import type { LoaderFunctionArgs } from '@remix-run/node'
import { json, redirect } from '@remix-run/node'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteLoaderData,
} from '@remix-run/react'
import { getEnv } from '~/utils/env.server.ts'
import { ClientHintCheck, getHints } from '~/utils/client-hints.tsx'
import { getDomainUrl } from '~/utils/misc.ts'
import { GeneralErrorBoundary } from '~/components/error-boundary.tsx'
import { Progress } from '~/components/progress.tsx'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef } from 'react'
import { requireLocale } from '~/utils/i18next.server.ts'
import { GtmScript } from '~/utils/gtm.tsx'
import { useDatadogRum } from '~/utils/data-dog.ts'
import { useTrackExperimentsData } from '~/utils/experiments.ts'
import { api } from '~/utils/gql-client.server.ts'
import type { UnwrapLoaderData } from '~/utils/loader-unwrap.ts'

import './styles/tailwind.css'
import '@rouvydev/web-components/fonts.css'
import { getPageState } from '~/utils/page-state.ts'
import { useDelegatedReactRouterHashLinks } from '~/utils/delegated-react-router-hash-link.ts'
import { PublicationState } from '~/model/api.ts'
import { resolveStaticRedirects } from '~/utils/redirects.server.ts'

export let handle = {
  i18n: [
    'common',
    'pricing',
    'collections',
    'about',
    'blog',
    'activity',
    'contact',
    'press',
    'pricing',
    'terms',
  ],
}

export async function loader({ request, params }: LoaderFunctionArgs) {
  const locale = await requireLocale(request, params)
  const { publicationState } = await getPageState(request)

  const { footer } = await api.FooterLinkGroups({
    locale,
    publicationState,
  })
  const { navbar } = await api.DefaultNavbar({
    locale,
    publicationState,
  })

  const url = new URL(request.url)

  const { redirect: redirectResult } = await api.Redirect({
    source: url.pathname,
    publicationState: PublicationState.Live,
  })

  if (redirectResult) {
    return redirect(
      redirectResult.destination,
      redirectResult.permanent ? 301 : 302,
    )
  }

  const staticRedirect = resolveStaticRedirects(url.pathname, url.search)

  if (staticRedirect) {
    return redirect(
      staticRedirect.destination,
      staticRedirect.permanent ? 301 : 302,
    )
  }

  return json({
    locale,
    ENV: getEnv(),
    footer: footer,
    navbarSettings: navbar?.navbarSettings,
    requestInfo: {
      hints: getHints(request),
      origin: getDomainUrl(request),
      path: new URL(request.url).pathname,
    },
  })
}

export function useRootLoaderData() {
  return useRouteLoaderData<typeof loader>('root')
}

export function useUnwrappedRootLoaderData() {
  return useRouteLoaderData<typeof loader>('root') as UnwrapLoaderData<
    typeof loader
  >
}

export function useChangeLanguage(locale: string) {
  const { i18n } = useTranslation()
  useEffect(() => {
    void i18n.changeLanguage(locale)
  }, [locale, i18n])
}

function Document({
  children,
  ENV = {},
  locale,
}: {
  children: React.ReactNode
  ENV?: Record<string, string>
  locale?: string
}) {
  const htmlRef = useRef(null)
  useDelegatedReactRouterHashLinks(htmlRef)

  return (
    <html lang={locale} className="h-full scroll-smooth" ref={htmlRef}>
      <head>
        <ClientHintCheck />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="h-full bg-background-canvas">
        {children}
        <Progress />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(
              ENV,
            )}; window.dataLayer = window.dataLayer || []`,
          }}
        />
        <ScrollRestoration />
        <Scripts />
        {ENV?.GTM && (
          <GtmScript gtmTrackingId={ENV.GTM} locale={locale ?? 'en'} />
        )}
      </body>
    </html>
  )
}

export default function App() {
  const { locale, ENV } = useLoaderData<typeof loader>()
  useChangeLanguage(locale)
  useDatadogRum(
    ENV.DATADOG_APPLICATION_ID,
    ENV.DATADOG_CLIENT_TOKEN,
    ENV.RELEASE_VERSION,
  )
  useTrackExperimentsData()

  return (
    <Document locale={locale} ENV={ENV}>
      <Outlet />
    </Document>
  )
}

export function ErrorBoundary() {
  return (
    <Document>
      <GeneralErrorBoundary />
    </Document>
  )
}
